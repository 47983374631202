/* eslint-disable no-undef */
declare let window: Window & { MeilisearchModule: any }
// eslint-disable-next-line no-var
declare var __webpack_public_path__: string

__webpack_public_path__ = window.MeilisearchModule.urls.publicPath

export default {
  async sync() {
    return import(/* webpackChunkName: "meilisearchSync" */ './admin/meilisearchSync/index')
  },
  async filterConfig() {
    return import(/* webpackChunkName: "meilisearchFilterConfig" */ './admin/meilisearchFilterConfig/index')
  },
  async search() {
    return import(/* webpackChunkName: "meilisearch" */ './front/meilisearch/index')
  },
}
